import React from 'react';

import Link from 'next/link';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    ColoredBackground,
    DisclaimerText,
    Heading,
    Image,
    Section,
    SubHeading,
} from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import fbaCertifiedCardContent from '@/config/page-data/fbaCertifiedCardContent';

import FordLandingCrawlPathFooterContainer from './landing/FordLandingCrawlPathFooterContainer';

function FordSEOContentContainer() {
    const device = useDevice();

    const {
        brand: [, { base_url: baseUrl }],
    } = useFeatures(['brand']);

    const isXs = _get(device, 'is.xs', false);

    const cardAnalyticsHandlers = {
        gold: (event) => sendClick('fbaCompareContentClick', event, { section: 'gold' }),
        blue: (event) => sendClick('fbaCompareContentClick', event, { section: 'blue' }),
        ev: (event) => sendClick('fbaCompareContentClick', event, { section: 'ev' }),
    };

    const renderCertCards = () => (
        <div className="display-flex flex-wrap row panel panel-default margin-horizontal-2">
            {fbaCertifiedCardContent.map(({ alt, image, heading, description, section }) => (
                <div
                    key={heading}
                    className="col-xs-12 col-sm-4 padding-4 margin-vertical-2"
                >
                    <Image
                        className="center-block img-responsive-scale"
                        src={`${baseUrl}${image}`}
                        alt={alt}
                        aria-hidden="true"
                        height="253"
                        width="380"
                    />
                    <div className="margin-top-5">
                        <Heading
                            size="500"
                            className="margin-bottom-1"
                        >
                            {heading}
                        </Heading>
                        {description(cardAnalyticsHandlers[section])}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
            <ColoredBackground uiContext="info">
                <div className="container margin-bottom-7 text-center padding-top-6 padding-bottom-7">
                    <Heading className="col-sm-10 col-sm-offset-1 margin-bottom-4">
                        Ford Blue Advantage Online Shopping Tool Helps You Easily Find and Purchase Your Ideal Vehicle
                    </Heading>
                    <SubHeading
                        size="500"
                        className="col-sm-10 col-lg-8 col-sm-offset-1 col-lg-offset-2"
                    >
                        Each Ford Blue Advantage certified used vehicle must pass a detailed multi-point inspection
                        before becoming Gold, EV or Blue Certified.
                    </SubHeading>
                </div>
            </ColoredBackground>

            <div className="container padding-top-2 margin-bottom-7">
                <SubHeading
                    className="margin-bottom-5 margin-left-2"
                    size="600"
                >
                    Ford Blue Advantage Eligibility
                </SubHeading>
                {renderCertCards()}
                <div className="row padding-top-7 margin-horizontal-1">
                    <Image
                        className="col-xs-12 col-sm-4 col-md-3 pull-left margin-bottom-2"
                        src={`${baseUrl}content/static/img/fba/landing/ford-${isXs ? 'xs' : 'lg'}.webp`}
                        alt=""
                        aria-hidden="true"
                        height="auto"
                        width={`${isXs ? '353' : '416'}`}
                    />
                    <Section
                        alignment="left"
                        uiContext="section-2"
                        heading="Ford Blue Advantage 14-Day/1,000-Mile Money Back Guarantee*"
                        className="col-xs-12 col-sm-8 col-md-9"
                    >
                        <p>
                            Purchase confidently with our 14-Day/1,000-Mile (whichever comes first) Money Back Guarantee.*
                            That means you can return your Ford Blue Advantage used vehicle to the Ford Dealer you purchased
                            it from, and they&rspos;ll work with you to find another vehicle to better suit your needs.
                            {' '}
                            <Link
                                href="/all/money-back-guarantee"
                                onClick={(event) => sendClick('fbaMoneyBackGuaranteeClick', event, {})}
                            >
                                Find certified used vehicles with a money back guarantee
                            </Link>
                            .
                        </p>
                    </Section>

                </div>
                <DisclaimerText
                    className="margin-left-1 margin-bottom-7 margin-horizontal-3"
                >
                    *Only available at participating Ford Dealers. Vehicle must be returned within 14 days/1,000 miles (whichever comes first) from the original sales date and mileage as stated on bill of sale and returned in same condition as sold (excludes normal wear and use).
                    Dealer cannot accept a return if the vehicle has a lien or other encumbrance. Original trade-in vehicle will not be returned to you. Purchasing a different vehicle may require a new credit application with different financial terms than the original contract.
                    If you have already returned a Ford Blue Advantage vehicle to a Ford Dealer within the last six months, you are not eligible for the Money Back Guarantee program and your request to return will not be honored. See your dealer for important program guidelines and restrictions.
                </DisclaimerText>
            </div>
            <ColoredBackground>
                <div className="container">
                    <div className="margin-horizontal-3">
                        <FordLandingCrawlPathFooterContainer />
                    </div>
                </div>
            </ColoredBackground>
        </>
    );
}

export default FordSEOContentContainer;
